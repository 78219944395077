<template>
    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
        <div v-if="isContentLoaded">
            <div class="iq-card-header d-flex justify-content-between">
                <apex-chart-header :disable-range-selection="true" :header="header" :header-icon="headerIcon"></apex-chart-header>
            </div>
            <div class="card-body iq-card-body p-1" style="position: relative;">
                <apexchart v-if="isContentLoaded" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </iq-card>
</template>
<script>
    import Loader from "../../components/shared/Loader";
    import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

    import ChartService from "../../services/chart";
    import "../../plugins/apexchart";

    export default {
        components: {
            Loader,
            ApexChartHeader
        },
        props: {
            dataUrl: String,
            dateMin: String,
            dateMax: String,
            dataObject: Object
        },
        data() {
            return {
                date: { startDate: '', endDate: '' },
                isContentLoaded: false,
                header: '',
                headerIcon: "",
                series: [],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: false,
                                zoomin: 10,
                                zoomout: 10,
                                pan: true,
                                reset: true,
                            },
                        },
                        defaultLocale: 'tr',
                        locales: [{
                            name: 'tr',
                            options: {
                                toolbar: {
                                    "exportToSVG": this.$t('DownloadSvg'),
                                    "exportToPNG": this.$t('DownloadPng'),
                                    "menu": this.$t('Menu'),
                                    "selection": this.$t('Selection'),
                                    "selectionZoom": this.$t('SelectionZoom'),
                                    "zoomIn": this.$t('ZoomIn'),
                                    "zoomOut": this.$t('zoomOut'),
                                    "pan": this.$t('Panning'),
                                    "reset": this.$t('ResetZoom')
                                }
                            }
                        }]
                    },
                    dataLabels: {
                        enabled: true,
                        //textAnchor: 'start',
                        //formatter: function (val) {
                        //    if (val != null) {
                        //        return val + ": TL"
                        //    }
                        //},
                        //offsetX: 0,
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        row: {
                            colors: ['#E4EEF0', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    }
                }
            }
        },
        methods: {
            getData() {
                ChartService.getChartData(this.computedUrl)
                    .then(response => {
                        if (response) {
                          this.setData(response);
                        }
                });
            },
            setData(filledChartData) {
                let $this = this;

                if (filledChartData.isZoom) {
                    $this.chartOptions.chart.zoom.enabled = filledChartData.isZoom
                }

                $this.series = filledChartData.series;
                for (var i = 0; i < $this.series.length; i++) {
                    $this.series[i].name = $this.$t($this.series[i].name);
                }
                $this.header = this.$t(filledChartData.chartHeader);
                $this.headerIcon = filledChartData.chartHeaderIcon ?? "";
                $this.chartOptions.xaxis = filledChartData.xaxis;
                $this.isContentLoaded = true;
            }
        },
        watch: {
            date: function (newDate) {
                console.dir(newDate)
                this.getData()
            },
            computedUrl: {
                handler: function () {
                    if (this.computedUrl)
                        this.getData();
                },
                immediate: true
            },
            chartData: {
                handler: function () {
                    if (this.chartData)
                        this.setData(this.chartData);
                },
                immediate: true
            }
        },
        computed: {
            chartUniqueId: function () {
                return "apexLineChart"
            },
            computedUrl: function () {
                let $this = this;
                if ($this.dateMin && $this.dateMax) {
                    return $this.dataUrl + "?d1=" + $this.dateMin + "&d2=" + $this.dateMax;
                }
                else {
                    return $this.dataUrl;
                }
            },
            chartData: function () {
                return this.dataObject;
            }
        },
        mounted: function () {
        }
    }
 </script>

<style lang="scss" scoped>
    ::v-deep .apexcharts-menu-item.exportCSV {
        display: none;
    }

    ::v-deep .apexcharts-menu.apexcharts-menu-open {
        min-width: 82px !important;
    }
</style>
