<template>
  <b-container fluid>
    <iq-card v-if="(bmi != 0 || bmr != 0) && weightTrackingLength > 0">
      <template v-slot:body>
        <b-row>
          <b-col md="6">
            <div v-if="bmi != 0">
              <img v-if="bmi < 18.5" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-blue.png" style="width:35px;" />
              <span v-if="bmi < 18.5" style="color: #627282;font-weight: 600;font-size: 18px;">{{ bmi }} kg/m2</span>

              <img v-if="bmi >= 18.5 && bmi <= 24.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-green.png" style="width:35px;" />
              <span v-if="bmi >= 18.5 && bmi <= 24.9" style="color: #627282;font-weight: 600;font-size: 18px;">{{ bmi }} kg/m2</span>

              <img v-if="bmi >= 25 && bmi <= 29.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-yellow.png" style="width:35px;" />
              <span v-if="bmi >= 25 && bmi <= 29.9" style="color: #627282;font-weight: 600;font-size: 18px;">{{ bmi }} kg/m2</span>

              <img v-if="bmi >= 30 && bmi <= 34.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-orange.png" style="width:35px;" />
              <span v-if="bmi >= 30 && bmi <= 34.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-orange.png"
                    style="color: #627282;font-weight: 600;font-size: 18px;">{{ bmi }} kg/m2</span>

              <img v-if="bmi >= 35 && bmi <= 39.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-red.png" style="width:35px;" />
              <span v-if="bmi >= 35 && bmi <= 39.9" style="color: #627282;font-weight: 600;font-size: 18px;">{{ bmi }} kg/m2</span>

              <img v-if="bmi>40" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-gray.png" style="width:35px;" />
              <span v-if="bmi>40" style="color: #627282;font-weight: 600;font-size: 16px;">{{ bmi }} kg/m2</span>

              <br />

              <span v-if="bmi < 18.5" style="color:#3498DB;position: absolute;top: 25px;left: 50px;font-size: 0.9rem;">{{$t("UnderWeight") }}</span>

              <span v-if="bmi >= 18.5 && bmi <= 24.9" style="color:#2ECC71;position: absolute;top: 25px;left: 45px;font-size: 0.9rem;">{{$t("Normal")}}</span>

              <span v-if="bmi >= 25 && bmi <= 29.9" style="color:#F1C40F;position: absolute;top: 25px;left: 50px;font-size: 0.9rem;">{{$t("OverWeight")}}</span>

              <span v-if="bmi >= 30 && bmi <= 34.9" src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/man-orange.png"
                    style="color:#E67E22;position: absolute;top: 25px;left: 50px;font-size: 0.9rem;">{{$t("Obese")}}</span>

              <span v-if="bmi >= 35 && bmi <= 39.9" style="color:#E74C3C;position: absolute;top: 25px;left: 50px;font-size: 0.9rem;">{{$t("ExtremlyObese")}}</span>
            </div>
          </b-col>
          <b-col md="6">
            <div v-if="bmr != 0">
              <img src="https://s3.stellamedi.com/stellamedi-cdn/weight-tracking/clock.png" style="width:35px;" />&nbsp;&nbsp;
              <label style="color: #627282; font-weight: 600; font-size: 16px;">
                {{ bmr }} {{ $t('kcal/day')}}
              </label>
            </div>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-form-group class="col-md-3" :label="$t('Height(WTM)')" label-for="height">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="icon"><i class="fa fa-ruler"></i></span>
              </div>
              <input type="text"
                     :placeholder="$t('Height(WTM)')"
                     v-model="customer.height"
                     class="form-control"
                     @focusout="updateCustomerInformation()" />
              <div class="input-group-append">
                <span class="input-group-text" id="icon">cm</span>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="col-md-4">
            <label>{{$t('IdealWeight')}}</label>&nbsp;
            <small class="text-primary">({{$t('IdealWeightIconMessage')}})</small>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="icon" @click="calculateAndUpdateIdealWeight()">
                  <i class="fa fa-weight"></i>
                </span>
              </div>
              <input type="text"
                     :placeholder="$t('IdealWeight')"
                     v-model="customer.idealWeight"
                     class="form-control"
                     @focusout="updateCustomerInformation()" />
              <div class="input-group-append">
                <span class="input-group-text" id="icon">kg</span>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="col-md-5" label-for="activityLevel" :label="$t('ActivityLevel')">
            <v-select transition="" v-model="customer.activityLevel"
                      :reduce="s => s.id"
                      label="name"
                      :options="activityLevels">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
        </b-row>

        <b-row v-if="!(customer.gender != null && customer.age != null && customer.height != null && customer.height > 0)">
          <b-col md="12">
            <b-alert class="p-1" :show="true" variant="warning">
              <div class="iq-alert-icon">
                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="2xl" />
              </div>
              <div class="iq-alert-text">{{$t('IdealWeightCalculationMessage')}}</div>
            </b-alert>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class="text-right">
      <template v-slot:body>
        <b-button v-if="weightTrackingLength > 0 && $store.getters['Auth/checkPermission']('customer_customerweighttrackingdownload')"
                  variant="outline-primary" @click="downloadReport(customerId)"
                  class="mr-1">
          <font-awesome-icon icon="fa-solid fa-file-arrow-down" size="xl" />
        </b-button>

        <b-button v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingnew')" variant="outline-primary">
          <router-link :to="{ name: 'weightTracking.new', params: {customerId:customerId} }">
            <span class="wt-hover"><i class="fa fa-plus"></i>&nbsp;{{$t('NewWeightTracking')}}</span>
          </router-link>
        </b-button>
      </template>
    </iq-card>

    <iq-card>
      <template v-slot:body>
        <WeightTrackingList :customerId="customerId" :weight-tracking-length="weightTrackingLength"
                            @update:weight-tracking-length="weightTrackingLength = $event"
                            @triggerCalculations="calculations" @triggerCustomerInfo="getCustomerInformation"
                            @triggerWeightTrackingChartData="getWeightTrackingChartData" />
      </template>
    </iq-card>

    <div v-if="weightTrackingLength > 0">
      <apex-line-chart :data-object="weightTrackingChartData" :hide-on-empty-data="true"></apex-line-chart>
    </div>

  </b-container>
</template>
<script>
  import customerWeightService from '../../../services/customerWeight'
  import chartService from '../../../services/chart'
  import { downloadWeightTracking } from '../../../helpers/fileDownloads'

  import WeightTrackingList from '../CustomerWeightTracking/WeightTrackingList'
  import ApexLineChart from '../../../components/apexCharts/ApexLineChart';

  export default {
    name: 'CustomerWeightTracking',
    props: {
      customerId: String
    },
    components: {
      WeightTrackingList,
      ApexLineChart
    },
    data() {
      return {
        customer: {
          gender: null,
          age: null,
          height: 0,
          currentWeight: null,
          idealWeight: 0,
          activityLevel: null,
        },

        bmi: 0,
        bmr: 0,
        activityLevels: [],

        weightTrackingLength: 0,

        weightTrackingChartDataUrl: '',
        weightTrackingChartData: {},

        pageLoaded: false
      }
    },
    methods: {
      getActivityLevels() {
        customerWeightService.getActivityLevels().then((response) => {
          if (response) {
            this.activityLevels = response;
          }
        });
      },
      getCustomerInformation() {
        customerWeightService.getCustomerInformation(this.customerId).then((response) => {
          if (response) {
            this.customer = response;
          }
        }).finally(() => {
          this.pageLoaded = true;
          this.calculations();
        });
      },
      calculations() {
        this.calculateBmi();
        this.calculateBmr();
      },
      calculateBmi() {
        if (this.customer.currentWeight != null && this.customer.currentWeight > 0 && this.customer.height != null && this.customer.height > 0) {
          let bmiCalculationResult = 0;

          let heightM2 = (this.customer.height / 100) * (this.customer.height / 100);

          bmiCalculationResult = (this.customer.currentWeight / heightM2);

          this.bmi = Number((bmiCalculationResult).toFixed(2));
        }
      },
      calculateBmr() {
        if (this.customer.gender != null && this.customer.age != null && this.customer.currentWeight != null && this.customer.currentWeight > 0 && this.customer.height != null && this.customer.height > 0 && this.customer.activityLevel != null) {
          let bmrCalculationResult = 0;

          if (this.customer.gender == 1)// 1 - female
            bmrCalculationResult = 655 + (9.563 * this.customer.currentWeight) + (1.850 * this.customer.height) - (4.676 * this.customer.age);
          else if (this.customer.gender == 2)// 2 - male
            bmrCalculationResult = 66.5 + (13.76 * this.customer.currentWeight) + (5.003 * this.customer.height) - (6.755 * this.customer.age);

          if (this.customer.activityLevel == 1)
            bmrCalculationResult = bmrCalculationResult * 1.2;
          else if (this.customer.activityLevel == 2)
            bmrCalculationResult = bmrCalculationResult * 1.375;
          else if (this.customer.activityLevel == 3)
            bmrCalculationResult = bmrCalculationResult * 1.55;
          else if (this.customer.activityLevel == 4)
            bmrCalculationResult = bmrCalculationResult * 1.725;
          else
            bmrCalculationResult = bmrCalculationResult * 1.9;

          this.bmr = Number((bmrCalculationResult).toFixed(2));
        }
      },
      calculateAndUpdateIdealWeight() {
        this.calculateIdealWeight();
        this.updateCustomerInformation();
      },
      calculateIdealWeight() {
        if (this.customer.gender != null && this.customer.age != null && this.customer.height != null && this.customer.height > 0) {
          let idealWeightCalculationResult = 0;

          if (this.customer.gender == 1)// 1 - female
            idealWeightCalculationResult = 45.5 + 2.3 * ((this.customer.height / 2.54) - 60);
          else if (this.customer.gender == 2)// 2 - male
            idealWeightCalculationResult = 50 + 2.3 * ((this.customer.height / 2.54) - 60);

          this.customer.idealWeight = Number((idealWeightCalculationResult).toFixed(2));
        }
      },
      updateCustomerInformation() {
        if (this.pageLoaded) {
          let height = this.customer.height == null ? 0 : this.customer.height;
          let idealWeight = this.customer.idealWeight == null ? 0 : this.customer.idealWeight;
          let activityLevel = this.customer.activityLevel == null ? 0 : this.customer.activityLevel;
          let currentWeight = this.customer.currentWeight == null ? 0 : this.customer.currentWeight;

          customerWeightService.updateCustomerInformation(this.customerId, height, idealWeight, activityLevel, currentWeight).then((response) => {
            if (response) {
              this.$toastr.success(this.$t("Success"));
            } else {
              this.$toastr.error(this.$t("Error"));
            }
          });
        }
      },
      downloadReport(customerId) {
        downloadWeightTracking(customerId);
      },
      getWeightTrackingChartData() {
        chartService.getChartData(this.weightTrackingChartDataUrl).then((response) => {
          if (response) {
            this.weightTrackingChartData = response;
          }
        });
      }
    },
    watch: {
      "customer.height": function (val) {
        if (val > 0) {
          this.calculations();
        }
      },
      "customer.activityLevel": function (val) {
        if (val > 0) {
          this.calculateBmr();
          this.updateCustomerInformation();
        }
      }
    },
    mounted() {
      this.weightTrackingChartDataUrl = '/customerWeightTracking/WeightTrackingChartData?customerId=' + this.customerId;

      this.getActivityLevels();
      this.getCustomerInformation();

      this.getWeightTrackingChartData();
    }
  }
</script>

<style scoped>
  .btn-outline-primary:hover a .wt-hover {
    color: #fff !important;
  }
</style>
