<template>
  <b-row>
    <b-col class="pl-5 pr-5" md="12">
      <b-form-group label-cols-md="2" content-cols-md="6" :label="$t('Forms')" label-for="fname">
        <v-select transition="" v-model="selectedForm"
                  :reduce="s => s" label="displayName"
                  :options="formList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </b-form-group>
    </b-col>
    <smart-duo filterOptionsUrl="/CustomerForm/Vue"
               customRenderFor="CustomerSummary" :customerId="customerId"></smart-duo>
  </b-row>
</template>
<script>
  import SmartDuo from '../Shared/SmartDuo'
  import customerFormService from '../../../services/customerForm'

  export default {
    name: 'CustomerFormMain',
    props: {
      customerId: String
    },
    components: {
      SmartDuo
    },
    data() {
      return {
        selectedForm: '',
        formList: []
      }
    },
    methods: {
      getFormList() {
        customerFormService.getFormList().then((result) => {
          this.formList = result;
        })
      },
      getForm(form) {
        this.$router.push({ name: form.routeName, params: form.routeProps });
      },
    },
    watch: {
      'selectedForm': function (val) {
        if (val) {
          this.getForm(val);
        }
      }
    },
    mounted() {
      this.getFormList();
    }
  }
</script>
