<template>
  <b-row>
    <b-col class="pl-5 pr-5" md="12">
      <b-form-group label-cols-md="2" content-cols-md="6" :label="$t('ConsentForm')" label-for="fname">
        <v-select transition="" v-model="consentForm"
                  :reduce="s => s" label="name"
                  :options="consentFormList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </b-form-group>
    </b-col>
    <smart-duo filterOptionsUrl="/ConsentForm/CustomerConsentFormVue"
               customRenderFor="CustomerSummary" :customerId="customerId"></smart-duo>
  </b-row>
</template>
<script>
  import SmartDuo from '../Shared/SmartDuo'
  import consentFormService from '../../../services/customerConsentForm'
  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'

  export default {
    name: 'ConsentForm',
    props: {
      customerId: String
    },
    components: {
      SmartDuo
    },
    data() {
      return {
        consentForm: '',
        consentFormList: []
      }
    },
    methods: {
      getConsentFormList() {
        consentFormService.getConsentFormList().then((result) => {
          this.consentFormList = result;
        })
      },
      insertCustomerConsentForm() {

        var url = `/consentForm/InsertCustomerConsentForm?`;

        if (this.consentForm.isCustom) {
          url += `customerId=${this.customerId}&customConsentFormId=${this.consentForm.id}`;
        }
        else {
          url += `customerId=${this.customerId}&reportName=${this.consentForm.reportName}&consentFormName=${this.consentForm.name}`;
        }

        consentFormService.insertCustomerConsentForm(url).then(response => {
          if (response) {
            this.$toastr.success(this.$t("Success"));
            this.consentForm = '';
            refreshSmartDuoData();
          }
          else
            this.$toastr.error(this.$t("Error"));
        });
      }
    },
    watch: {
      'consentForm.id': function (val) {
        if (val && val.length > 0) {
          this.insertCustomerConsentForm();
        }
      }
    },
    mounted() {
      this.getConsentFormList();
    }
  }
</script>
