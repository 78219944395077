import axios from './index'

export default {
  getFormList() {
    return axios.get('/customerForm/getCustomerFormList')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerForm(customerFormId, templateId) {
    let params = {
      id: customerFormId,
      customerFormTemplateID: templateId
    };

    return axios.post('/customerForm/dataCustomerForm', params)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewCustomerForm(model) {
    return axios.post('/customerForm/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
