<template>
  <div>
    <div v-if="rows > 0" class="table-responsive">
      <table class="table table-bordered table-striped table-hover"
             :items="items">
        <thead class="thead">
          <tr>
            <th>{{$t('Date')}}</th>
            <th>{{$t('Seance')}}</th>
            <th>{{$t('DietType')}}</th>
            <th>{{$t('BodyWeight')}}</th>
            <th>{{$t('BMI')}}</th>
            <th>{{$t('Fat')}}</th>
            <th>{{$t('FatRate')}}</th>
            <th v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingedit') || $store.getters['Auth/checkPermission']('customer_customerweighttrackingdelete')">
              {{$t('Actions')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td style="font:bolder;">{{ item.date | dateFormat }}</td>
            <td>{{ item.seance }}</td>
            <td>{{ item.dietType }}</td>
            <td>
              <span v-if="item.status == null">{{ item.bodyWeight }}</span>
              <!--GOAL : LOSE WEIGHT(-) --->
              <span v-if="item.goal == '-' && item.status == 1" style="color: #fd397a !important;">
                {{ item.bodyWeight }}
                <font-awesome-icon icon="fa-solid fa-caret-up" />
              </span>
              <!--style="color: #fd397a !important; margin-left:5px;"-->
              <span v-if="item.goal == '-' && item.status == 0">{{ item.bodyWeight }}</span>

              <span v-if="item.goal == '-' && item.status == -1" style="color: #1dc9b7 !important;">
                {{ item.bodyWeight }}
                <font-awesome-icon icon="fa-solid fa-caret-down" />
              </span>
              <!--GOAL : LOSE WEIGHT(-) --->
              <!------>
              <!--GOAL : GAIN WEIGHT(+) --->
              <span v-if="item.goal == '+' && item.status == -1" style="color: #fd397a !important;">
                {{ item.bodyWeight }}
                <font-awesome-icon icon="fa-solid fa-caret-down" />
              </span>

              <span v-if="item.goal == '+' && item.status == 0">{{ item.bodyWeight }}</span>

              <span v-if="item.goal == '+' && item.status == 1" style="color: #1dc9b7 !important;">
                {{ item.bodyWeight }}
                <font-awesome-icon icon="fa-solid fa-caret-up" />
              </span>
              <!--GOAL : GAIN WEIGHT(+) --->
            </td>
            <td>{{ item.bmi }}</td>
            <td>{{ item.fat }}</td>
            <td>{{ item.fatRate }}</td>

            <td v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingedit') || $store.getters['Auth/checkPermission']('customer_customerweighttrackingdelete')">
              <div class="kt-section__content" style="justify-content:right">
                <div role="group" class="btn-group btn-group-sm">

                  <router-link v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingedit')"
                               :to="{ name: 'weightTracking.edit', params: {weightTrackingId:item.id} }" tag="button" class="btn btn-light">
                    <font-awesome-icon icon="fa-light fa-pen-to-square" />
                  </router-link>

                  <delete-button v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingdelete')"
                                 :object-id="item.id" :object-delete-url="'/CustomerWeightTracking/Delete'" />
                </div>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <no-data></no-data>
    </div>

    <div id="weightTracking_refresher" @click="refreshPageData()" />

  </div>
</template>

<script>
  import customerWeightService from '../../../services/customerWeight'

  import DeleteButton from '../../../components/shared/DeleteButton'
  import NoData from '../../../components/shared/NoData'

  export default {
    name: 'WeightTrackingList',
    props: {
      customerId: String,
      weightTrackingLength: Number
    },
    components: {
      DeleteButton,
      NoData
    },
    data() {
      return {
        items: []
      }
    },
    methods: {
      getWeightTrackingList() {
        customerWeightService.getWeightTrackingList(this.customerId)
          .then(response => {
            this.items = response;
          }).finally(() => {
            this.rows = this.items?.length ?? 0;
          });
      },
      refreshPageData() {
        this.getWeightTrackingList();
        this.$emit('triggerCalculations');
        this.$emit('triggerCustomerInfo');
        this.$emit('triggerWeightTrackingChartData');
      }
    },
    computed: {
      rows: {
        get() {
          return this.weightTrackingLength;
        },
        set(newValue) {
          console.dir(newValue)
          this.$emit('update:weight-tracking-length', newValue);
          return newValue;
        }
      },
    },
    mounted() {
      this.getWeightTrackingList();
    }
  }
</script>

<style scoped>
  .table td {
    padding: 0.30rem;
  }

  .table th {
    padding: 0.40rem;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: rgb(233, 238, 244,.30);
  }

    .table-striped tbody tr:nth-of-type(odd):hover, .table-striped tbody tr:nth-of-type(even):hover {
      background: rgba(110,167,175,.19);
    }

  .thead {
    background-color: rgb(233, 238, 244,.40);
  }
</style>
