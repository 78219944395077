<template>
  <b-container fluid>
    <b-row id="customer-summary">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <tab-nav :tabs="true" id="myTab-1">
              <tab-nav-items active id="forms-surveys-tab" ariaControls="FormsAndSurveys"
                             role="tab" :ariaSelected="true" :title="$t('FormsAndSurveys')" @click="changeTabName('FormsAndSurveys')" />

              <tab-nav-items id="consent-form-tab" ariaControls="ConsentForm"
                             role="tab" :ariaSelected="false" :title="$t('ConsentForm')" @click="changeTabName('ConsentForm')" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('customer_customerweighttrackingshow')"
                             id="weight-tracking-tab" ariaControls="WeightTracking"
                             role="tab" :ariaSelected="false" :title="$t('WeightTracking')" @click="changeTabName('WeightTracking')" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('customer_obesitytrackingshow')"
                             id="obesity-tracking-tab" ariaControls="ObesityTracking"
                             role="tab" :ariaSelected="false" :title="$t('ObesityTracking')" @click="changeTabName('ObesityTracking')" />
            </tab-nav>
            <tab-content id="myTabContent">
              <tab-content-item v-if="tab == 'FormsAndSurveys'" active
                                id="FormsAndSurveys" aria-labelled-by="forms-surveys-tab">
                <CustomerFormMain :customerId="customerId" />
              </tab-content-item>

              <tab-content-item v-if="tab == 'ConsentForm'"
                                id="ConsentForm" aria-labelled-by="consent-form-tab">
                <ConsentForm :customerId="customerId" />
              </tab-content-item>

              <tab-content-item v-if="tab == 'WeightTracking'"
                                id="WeightTracking" aria-labelled-by="weight-tracking-tab">
                <CustomerWeightTracking :customerId="customerId" />
              </tab-content-item>

              <tab-content-item v-if="tab == 'ObesityTracking'"
                                id="ObesityTracking" aria-labelled-by="obesity-tracking-tab">
                <no-data></no-data>
              </tab-content-item>
            </tab-content>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import TabNav from '../../../components/xray/tab/tab-nav'
  import TabNavItems from '../../../components/xray/tab/tab-nav-items'
  import TabContent from '../../../components/xray/tab/tab-content'
  import TabContentItem from '../../../components/xray/tab/tab-content-item'
  import CustomerFormMain from '../CustomerForm/CustomerFormMain'
  import ConsentForm from '../ConsentForm/ConsentForm'
  import CustomerWeightTracking from '../CustomerWeightTracking/CustomerWeightTracking'
  import NoData from '../../../components/shared/NoData'

  export default {
    name: 'SummaryForms',
    props: {
      customerId: String
    },
    components: {
      TabNav,
      TabNavItems,
      TabContent,
      TabContentItem,
      CustomerFormMain,
      ConsentForm,
      CustomerWeightTracking,
      NoData
    },
    data() {
      return {
        tab: 'FormsAndSurveys'
      }
    },
    methods: {
      changeTabName(tabName) {
        this.tab = tabName;
      }
    }
  }
</script>

<style>
  #customer-summary .card-body {
    padding: .5rem !important;
  }
</style>
