import axios from './index'

export default {
  getLastSeanceNumber(customerID) {
    return axios.get("/customerWeightTracking/getLastSeanceNumber?customerId=" + customerID)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getWeightTracking(id) {
    return axios.get("/customerWeightTracking/GetCustomerWeightTracking?id=" + id)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerInformation(customerID) {
    return axios.get("/customerWeightTracking/GetCustomerInformation?customerId=" + customerID)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitWeightTracking(model) {
    return axios.post("/customerWeightTracking/Upsert", model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getActivityLevels() {
    return axios.get("/data/activityLevel")
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getWeightTrackingList(customerId) {
    return axios.get(`/customerWeightTracking/List?customerId=${customerId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCustomerInformation(customerId, height, idealWeight, activityLevel, currentWeight) {
    return axios.post(`/customerWeightTracking/updateCustomerInformation?customerId=${customerId}&height=${height}&idealWeight=${idealWeight}&activityLevel=${activityLevel}&currentWeight=${currentWeight}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  downloadWeightTracking(customerId) {
    return axios.get("/customerWeightTracking/Download ? customerId = " + customerId, { responseType: 'arraybuffer' })
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
